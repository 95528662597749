import { AssetIds } from './types';

const devIds: AssetIds = {
    Title: 181,
    Icon: 182,
    Main: 183,
    Desc: 184,
    DisplayUrl: 1813,
    Sponsored: 1815,
};

const prodIds: AssetIds = {
    Title: 161,
    Icon: 162,
    Main: 163,
    Desc: 164,
    DisplayUrl: 1613,
    Sponsored: 1615,
};

export function getAssetIds(): AssetIds {
    switch (process.env.DLD) {
        case 'dev':
            return devIds;
        case 'tst':
            return devIds;
        case 'prod':
            return prodIds;
        default:
            return devIds;
    }
}
