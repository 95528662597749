import { Id } from 'types';

// sob: ids for users with hidden posts are supposed to be hardcoded as for now https://jira.finam.ru/browse/WTT-11010
const usersWithHiddenComments: Id[] = [482378506];

export const useHideComments = (userId?: Id) => {
    if (!userId) return false;

    return usersWithHiddenComments.includes(userId);
};
