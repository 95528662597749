import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const RUSFlagIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 24 24">
        <g clipPath="url(#clip0_148_13481)">
            <path d="M23 2H1V8.66667H23V2Z" fill="white" />
            <path d="M23 8.66663H1V15.3333H23V8.66663Z" fill="#0039A6" />
            <path d="M23 15.3334H1V22H23V15.3334Z" fill="#D52B1E" />
        </g>
        <rect x="1.2" y="2.2" width="21.6" height="19.6" rx="1.8" stroke="#B9C6DF" strokeWidth="0.4" />
        <defs>
            <clipPath id="clip0_148_13481">
                <rect x="1" y="2" width="22" height="20" rx="2" fill="white" />
            </clipPath>
        </defs>
    </EmojiIconSvg>
);
