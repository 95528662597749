import React from 'react';
import PropTypes from 'prop-types';

import Instrument from './components';
import { STYLE } from './constants';

export {
    STYLE as INSTRUMENT_STYLE,
};

function InstrumentWidget(props) {
    const {
        url, logoUrl, name, classes, className, onClick, style, finamId, showPrice, willClose, as, tooltipEnabled,
        initPrice, initChangePercent, initDcpl, initTicker, forceReady, autoUpdate, customizedRender, currency,
        actions, isSuggestion,
    } = props;

    return (
        <Instrument
            url={url}
            logoUrl={logoUrl}
            name={name}
            classes={classes}
            className={className}
            onClick={onClick}
            style={style}
            finamId={finamId}
            currency={currency}
            showPrice={showPrice}
            willClose={willClose}
            as={as}
            tooltipEnabled={tooltipEnabled}
            customizedRender={customizedRender}
            initPrice={initPrice}
            initChangePercent={initChangePercent}
            initDcpl={initDcpl}
            initTicker={initTicker}
            forceReady={forceReady}
            autoUpdate={autoUpdate}
            actions={actions}
            isSuggestion={isSuggestion}
        />
    );
}

InstrumentWidget.propTypes = {
    finamId: PropTypes.number,
    currency: PropTypes.string,
    autoUpdate: PropTypes.bool,
    style: PropTypes.number,
    initTicker: PropTypes.string,
    initPrice: PropTypes.number,
    initChangePercent: PropTypes.number,
    initDcpl: PropTypes.number,
    showPrice: PropTypes.bool,
    name: PropTypes.string,
    classes: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    customizedRender: PropTypes.func,
    forceReady: PropTypes.bool,
    onClick: PropTypes.func,
    willClose: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]), // alp: flag for instrument tooltip, tooltip will be close after some action
    as: PropTypes.string,
    tooltipEnabled: PropTypes.bool,
    actions: PropTypes.shape({
        subscribe: PropTypes.element,
    }),
    isSuggestion: PropTypes.bool,
};

InstrumentWidget.defaultProps = {
    style: STYLE.PAINTED,
    finamId: undefined,
    currency: '',
    autoUpdate: true,
    initTicker: undefined,
    initPrice: undefined,
    initChangePercent: undefined,
    initDcpl: undefined,
    showPrice: true,
    name: '',
    classes: '',
    className: '',
    onClick: undefined,
    url: '',
    logoUrl: '',
    customizedRender: undefined,
    forceReady: false,
    willClose: '',
    as: 'a',
    tooltipEnabled: true,
    actions: null,
    isSuggestion: false,
};

export default InstrumentWidget;
