import { useMemo } from 'react';

import { FeedRecordObj } from 'types';

export enum ACTIVITY_CONTENT_TYPES {
    htmlParsed,
}

export const useActivityContentType = (obj: FeedRecordObj): ACTIVITY_CONTENT_TYPES => {
    const { type } = obj;

    const { activityType } = useMemo(() => {
        switch (type) {
            default: {
                return {
                    activityType: ACTIVITY_CONTENT_TYPES.htmlParsed,
                };
            }
        }
    }, []);

    return activityType;
};
