import set from 'lodash/set';
import isPlainObject from 'lodash/isPlainObject';

// Author: dg
// Выполняет рекурсивный (глубокий) мержд данных
// не изменяет объекты, который нет в подмерживаемом объекте, что позволяет избежать иммутабельности нетронутых
//   подъобектов (в отличии от lodash.merge)
// преимущество такого подхода - не перерендеривать элементы, данные которых в результате некоторого действия не были
//   изменены
// возвращает новый объект, не изменяя источник

// при всяком изменении здесь необходимо перезапустить тест /react/jest/services/utils/merge.test.js
// npm run test:react

// opts:
// - rewriteArrays - полностью переписать встреченные массивы, вместо того чтобы накладывать второй на первый
const merge = (state, data, opts = {}) => {
    if ((!isPlainObject(data) && !Array.isArray(data)) || (typeof data === 'function')) {
        return data;
    }

    // eslint-disable-next-line no-param-reassign
    const assign = (key, newState) => {
        newState[key] = merge((state || {})[key], data[key], opts);
    };

    if (Array.isArray(data)) {
        if (opts.rewriteArrays) {
            return [...data];
        }
        // dg: На бэке пустой объект сериализуется в пустой массив. Поэтому просто скипаем такой кейс
        if (!data.length) {
            return state || data;
        }

        const newState = [...(state || [])];
        data.forEach((dataElement, key) => {
            newState[key] = dataElement;
        });
        return newState;
    }

    const newState = {};
    Object.keys(data).forEach((key) => assign(key, newState));
    return { ...state, ...newState };
};

export const mergeByPath = (state, path, endData, opts = {}) => {
    const data = set(Array.isArray(state) ? [] : {}, path, endData);
    return merge(state, data, opts);
};

export default merge;
