import { useEffect, useRef } from 'react';

import { useToggle } from 'hooks/useToogle';
import { usePrevious } from 'hooks/usePrevious';

export const useSubscribe = (subscribed: boolean) => {
    const [showSubscribe, toggleShowSubscribed] = useToggle(!subscribed);
    const prevSubscribe = usePrevious<boolean>(subscribed);
    const showSubscribedTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
        if (subscribed) {
            showSubscribedTimeout.current = setTimeout(() => {
                if (showSubscribedTimeout.current) toggleShowSubscribed(false);
            }, 3000);
        } else if (prevSubscribe) {
            if (showSubscribedTimeout.current) {
                clearTimeout(showSubscribedTimeout.current);
                showSubscribedTimeout.current = null;
            }
            toggleShowSubscribed(true);
        }
    }, [subscribed]);

    return { showSubscribe };
};
