import React from 'react';
import PropTypes from 'prop-types';

import { STYLE, PERCENT_DCPL } from '../constants';
import { Painted3Instrument } from './painted3-instrument';
import { Painted4Instrument } from './painted4-instrument';

export function InstrumentUI(props) {
    const {
        url, logoUrl, title, className, style, changePercent, ticker, price, dcpl, currency, actions, isSuggestion,
    } = props;

    switch (style) {
        case STYLE.PAINTED3:
            return (
                <Painted3Instrument
                    className={className}
                    currency={currency}
                    changePercent={changePercent}
                    price={price}
                    dcpl={dcpl}
                    percentDcpl={PERCENT_DCPL}
                    ticker={ticker}
                    url={url}
                />
            );
        case STYLE.PAINTED4:
            return (
                <Painted4Instrument
                    ticker={ticker}
                    currency={currency}
                    price={price}
                    name={title}
                    changePercent={changePercent}
                    percentDcpl={PERCENT_DCPL}
                    dcpl={dcpl}
                    url={url}
                    logoUrl={logoUrl}
                    actions={actions}
                    isSuggestion={isSuggestion}
                />
            );
        default:
            throw new Error(`The style ${style} is unknown`);
    }
}

InstrumentUI.propTypes = {
    currency: PropTypes.string,
    style: PropTypes.number,
    ticker: PropTypes.string,
    price: PropTypes.number,
    changePercent: PropTypes.number,
    dcpl: PropTypes.number,
    title: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    actions: PropTypes.shape({
        subscribe: PropTypes.element,
    }),
};

InstrumentUI.defaultProps = {
    style: STYLE.PAINTED,
    currency: '',
    ticker: undefined,
    price: undefined,
    changePercent: undefined,
    dcpl: undefined,
    title: '',
    className: '',
    url: '',
    logoUrl: '',
    actions: null,
};
