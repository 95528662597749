import styled from 'styled-components';

import { ButtonIcon } from 'components/button-icon';

export const ButtonWrapper = styled(ButtonIcon)`
    div {
        color: ${({ theme }) => theme.label.negative};
    }
`;

export const AILabel = styled.span.attrs(({ title }) => ({
    title,
}))`
    color: ${({ theme }) => theme.label.negative};
    margin: 0 8px;
    cursor: pointer;
`;
