export function getPlacementId(): number {
    switch (process.env.DLD) {
        case 'dev':
            return 18;
        case 'tst':
            return 18;
        case 'prod':
            return 16;
        default:
            return 18;
    }
}
