import React from 'react';

import { IconSvg, IconComponent } from '.';

export const GlobeIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 12 12">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9001 5.99998C10.9001 8.70617 8.70629 10.9 6.0001 10.9C3.2939 10.9 1.1001 8.70617 1.1001 5.99998C1.1001 3.29378 3.2939 1.09998 6.0001 1.09998C8.70629 1.09998 10.9001 3.29378 10.9001 5.99998ZM5.49736 2.14337C5.68491 1.96488 5.85411 1.89998 6.0001 1.89998C6.14609 1.89998 6.31528 1.96488 6.50283 2.14337C6.69245 2.32381 6.8814 2.60383 7.04879 2.98045C7.34547 3.64799 7.54975 4.5636 7.59199 5.59998H4.40821C4.45044 4.5636 4.65472 3.64799 4.95141 2.98045C5.11879 2.60383 5.30775 2.32381 5.49736 2.14337ZM3.6076 5.59998C3.65014 4.4705 3.8716 3.44025 4.22036 2.65554C4.29131 2.4959 4.36857 2.34408 4.45212 2.20226C3.0728 2.76506 2.06871 4.05746 1.91936 5.59998H3.6076ZM1.91936 6.39998H3.6076C3.65014 7.52945 3.8716 8.5597 4.22036 9.34441C4.29131 9.50405 4.36857 9.65588 4.45212 9.79769C3.0728 9.23489 2.06871 7.94249 1.91936 6.39998ZM4.40821 6.39998H7.59199C7.54975 7.43635 7.34547 8.35196 7.04879 9.0195C6.8814 9.39612 6.69245 9.67614 6.50283 9.85658C6.31528 10.0351 6.14609 10.1 6.0001 10.1C5.85411 10.1 5.68491 10.0351 5.49736 9.85658C5.30775 9.67614 5.11879 9.39612 4.95141 9.0195C4.65472 8.35196 4.45044 7.43635 4.40821 6.39998ZM8.3926 6.39998C8.35005 7.52945 8.12859 8.5597 7.77984 9.34441C7.70889 9.50405 7.63162 9.65588 7.54807 9.79769C8.9274 9.23489 9.93149 7.94249 10.0808 6.39998H8.3926ZM10.0808 5.59998C9.93149 4.05746 8.9274 2.76506 7.54807 2.20226C7.63162 2.34408 7.70889 2.4959 7.77984 2.65554C8.12859 3.44025 8.35005 4.4705 8.3926 5.59998H10.0808Z"
        />
    </IconSvg>
);
