import tippy from 'tippy.js';

const service = {
    defaultOptions: {
        trigger: 'click',
        interactive: true,
        animateFill: false,
        arrow: true,
        zIndex: 410,
        theme: 'default',
        performance: true,
        placement: 'top',
        flip: false,
        maxWidth: '',
    },

    hideAll(duration) {
        const poppers = document.querySelectorAll('.tippy-popper');

        [].forEach.call(poppers, (popper) => {
            const tooltip = popper._tippy;

            if (tooltip.state.isVisible) {
                tooltip.popperInstance.disableEventListeners();
                tooltip.hide(duration);
            }
        });
    },

    setDefaults(options) {
        Object.assign(tippy.defaults, options);
    },

    updateAll() {
        Array.from(document.querySelectorAll('[data-tippy]'), el => el._tippy)
            .forEach((t) => {
                if (t.popperInstance) {
                    t.popperInstance.update();
                }
            });
    },
};

// pi: закрываем тултипы при событие documentClickClose,
// нужно из-за кривой реализации скрытия/раскрытия окон чата,
// WTT-5407, WTT-5584
window.addEventListener('documentClickClose', tippy.hideAll);

window.addEventListener('wtChangeLayout', service.updateAll);

export default service;
