import { CurrencySign } from 'types/currency';

// TODO:
// dmh: удалить это и исключить использование этой штуки,
// т.к. конвертация кода валюты в символ зависит от локали и должна делаться
// нативными средствами локализации.
//
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export const CurrencySigns: Record<string, CurrencySign> = {
    USD: '$',
    RUB: '₽',
    GBP: '£',
    EUR: '€',
};
