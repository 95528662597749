const getMobileDetect = (userAgent) => {
    const _buildCheckOS = word => () => navigator.appVersion.indexOf(word) !== -1;
    const isWindows = _buildCheckOS('Win');
    const isLinux = _buildCheckOS('Linux');
    const isMac = _buildCheckOS('Mac');

    const _buildCheckBrowser = word => () => Boolean(userAgent.match(new RegExp(word, 'i')));
    const isAndroid = _buildCheckBrowser('Android');
    const isIos = _buildCheckBrowser('iPhone|iPad|iPod');
    const isOperaMini = _buildCheckBrowser('Opera Mini');
    const isIEMobile = _buildCheckBrowser('IEMobile');
    const isChrome = _buildCheckBrowser('Chrome');
    const isSafari = () => !isChrome() && _buildCheckBrowser('Safari')();
    const isFirefox = _buildCheckBrowser('Firefox');
    const isIE = () => _buildCheckBrowser('MSIE')() || !!document.documentMode === true;

    const isMobile = () => Boolean(isAndroid() || isIos() || isOperaMini() || isIEMobile());
    const isDesktop = () => !isMobile();
    return {
        isMobile,
        isDesktop,
        isAndroid,
        isIos,

        isWindows,
        isLinux,
        isMac,

        isChrome,
        isFirefox,
        isSafari,
        isIE,
    };
};

export default getMobileDetect(navigator.userAgent);
