import { mediaQuery } from 'app/styled';

export const postSidePadding = `
    padding-left: 24px;
    padding-right: 24px;
    ${mediaQuery.lt960} {
        padding-left: 12px;
        padding-right: 12px;
    }
`;
