/* eslint-disable react/prop-types */
import React from 'react';
import { KEY_UP, KEY_DOWN } from 'keycode-js';

// arb: it could be any value, but it seems 53px is not too sharp and not too smooth scroll
const DELTA_SCROLL = 53;

const blockEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

export default (Component, style = {}) => class extends React.Component {
    constructor(props) {
        super(props);
        this.hocRef = React.createRef();
    }

    componentDidMount() {
        const node = this.hocRef.current;
        node.addEventListener('mousewheel', this.handleScroll);
        node.addEventListener('DOMMouseScroll', this.handleScroll);
        node.addEventListener('MozMousePixelScroll', this.handleScroll);
        // we cant prevent touchmove event cause event.cancelable of touchmove is false
        window.addEventListener('keydown', this.handleArrowKeys);
    }

    componentWillUnmount() {
        const node = this.hocRef.current;
        node.removeEventListener('mousewheel', this.handleScroll);
        node.removeEventListener('DOMMouseScroll', this.handleScroll);
        node.removeEventListener('MozMousePixelScroll', this.handleScroll);
        window.removeEventListener('keydown', this.handleArrowKeys);
    }

    handleArrowKeys = (e) => {
        if ([KEY_DOWN, KEY_UP].includes(e.keyCode) && this.hocRef.current.matches(':hover')) {
            const deltaY = (e.keyCode === KEY_UP ? -1 : 1) * DELTA_SCROLL;
            this.handleScroll(e, deltaY);
        }
    };

    handleScroll = (e, deltaY = (e.deltaY || e.detail)) => {
        const isTextarea = e.target.tagName === 'TEXTAREA';
        if (isTextarea && e.type === 'keydown') return;
        blockEvent(e);
        const node = isTextarea ? e.target : (this.props.scrollNode || this.hocRef.current);
        let scrollTop = node.scrollTop + deltaY;

        if (scrollTop < 0) {
            scrollTop = 0;
        }
        node.scroll(0, scrollTop);
    };

    scrollTo = (pos) => {
        this.hocRef.current.scrollTop = pos;
    };

    getComponentRef = (element) => {
        const { getComponentRef } = this.props;
        if (getComponentRef) {
            getComponentRef(element);
        }
    }

    render() {
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div ref={this.hocRef} style={{ overflow: 'hidden', ...style, ...(this.props.pullScrollStyle || {}) }} tabIndex={0}>
                <Component {...this.props} scrollTo={this.scrollTo} ref={this.getComponentRef} />
            </div>
        );
    }
};
