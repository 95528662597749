import { useMemo } from 'react';

import { FeedRecordObjMedia } from 'types';

export const useThumbnail = (firstImg: FeedRecordObjMedia) => {
    const [widthThumbnail, heightThumbnail] = useMemo(() => {
        if (!firstImg) {
            return [undefined, undefined];
        }

        if (firstImg?.width && firstImg?.height && firstImg.height > 600) {
            return [firstImg.width / (firstImg.height / 600), 600];
        }

        return [firstImg.width, firstImg.height];
    }, []);

    const showIfSizeSatisfies = widthThumbnail && widthThumbnail > 288;
    return {
        widthThumbnail,
        heightThumbnail,
        showIfSizeSatisfies,
    };
};
