const createTimeout = (callback, delay) => {
    const timeoutKey = setTimeout(callback, delay);
    return {
        abort() {
            clearTimeout(timeoutKey);
        },
    };
};

class RecordStats {
    constructor(recordId) {
        this.recordId = recordId;
        this.setToZero();

        this.unveil = false;
        this.readStartTime = null;
        this.readStartTimeAbsolute = null;
    }

    hasStats() {
        this._updateReadTime();

        return this.impressionCount > 0
            || this.readCount > 0
            || this.impressionTime > 0
            || this.readTime > 0;
    }

    serialize() {
        this._updateReadTime();

        return {
            recordId: this.recordId,
            impressionCount: this.impressionCount,
            readCount: this.readCount,
            impressionTime: this.impressionTime,
            readTime: this.readTime,
            readStartTimeAbsolute: this.readStartTimeAbsolute,
        };
    }

    flush() {
        const data = this.serialize();

        this.setToZero();

        return data;
    }

    setRecordShowMore(showMore = true) {
        if (this.unveil === showMore) {
            return;
        }

        this._updateReadTime();
        this.unveil = showMore;

        if (showMore && this.readStartTime) {
            this.readCount++;
            this.readStartTimeAbsolute = new Date();
        }
    }

    startRead() {
        if (this.readStartTime) {
            return;
        }
        this.impressionCount++;
        if (this.unveil) {
            this.readCount++;
            this.readStartTimeAbsolute = new Date();
        }

        this.readStartTime = new Date();
    }

    stopRead() {
        if (!this.readStartTime) {
            return;
        }

        this._updateReadTime();
        this.readStartTime = null;
        this.readStartTimeAbsolute = null;
    }

    _updateReadTime() {
        if (!this.readStartTime) {
            return;
        }
        const currentDate = new Date();
        const readTime = currentDate - this.readStartTime;
        this.impressionTime += readTime;
        if (this.unveil) {
            this.readTime += readTime;
        }
        this.readStartTime = currentDate;
    }

    setToZero() {
        this.impressionCount = 0;
        this.readCount = 0;
        this.impressionTime = 0;
        this.readTime = 0;
    }
}

class RecordStatsRegistry {
    constructor() {
        this.registry = {};
    }

    get(recordId) {
        if (!this.registry[recordId]) {
            this.registry[recordId] = new RecordStats(recordId);
        }

        return this.registry[recordId];
    }

    exist(recordId) {
        return !!this.registry[recordId];
    }

    serialize() {
        const data = {};

        Object.keys(this.registry).forEach((recordId) => {
            const record = this.registry[recordId];
            if (record.hasStats()) {
                data[record.recordId] = record.serialize();
            }
        });

        return data;
    }

    flush() {
        const data = [];

        Object.keys(this.registry).forEach((recordId) => {
            const record = this.registry[recordId];
            if (record.hasStats()) {
                data.push(record.flush());
            }
        });

        return data;
    }
}

export class StatsService {
    constructor(source, recordStartReadDelay) {
        this.source = source;
        this.recordStartReadDelay = recordStartReadDelay;
        this.visibleRecordIds = [];
        this.recordStartReadTimeouts = {};
        this.records = new RecordStatsRegistry();
    }

    setVisibleRecords(recordIds) {
        const visibleRecords = [];
        this.visibleRecordIds.forEach((recordId) => {
            if (recordIds.indexOf(recordId) === -1) {
                if (this.recordStartReadTimeouts[recordId]) {
                    this.recordStartReadTimeouts[recordId].abort();
                    delete this.recordStartReadTimeouts[recordId];
                }
                if (this.records.exist(recordId)) {
                    this.records.get(recordId).stopRead();
                }
            } else {
                visibleRecords.push(recordId);
            }
        });

        this.visibleRecordIds = visibleRecords;

        recordIds.forEach((recordId) => {
            if (this.visibleRecordIds.indexOf(recordId) !== -1) {
                return;
            }

            this.recordStartReadTimeouts[recordId] = createTimeout(() => {
                this.records.get(recordId).startRead();
            }, this.recordStartReadDelay);
            this.visibleRecordIds.push(recordId);
        });
    }

    setRecordShowMore(recordId, showMore = true) {
        if (showMore || this.records.exist(recordId)) {
            this.records.get(recordId).setRecordShowMore(showMore);
        }
    }

    getStats() {
        const data = this.records.serialize();
        const stats = [];
        function printRecordStats(record) {
            const impressionTime = Math.floor(record.impressionTime / 1000);
            const readTime = Math.floor(record.readTime / 1000);
            return `#${record.recordId}: ${impressionTime}(${record.impressionCount}), ${readTime}(${record.readCount})`;
        }

        this.visibleRecordIds.forEach((recordId) => {
            if (data[recordId]) {
                stats.push(printRecordStats(data[recordId]));
            }
        });

        if (stats.length) {
            stats.push('------------------------------');
        }

        Object.keys(data).forEach((recordId) => {
            if (this.visibleRecordIds.indexOf(data[recordId].recordId) === -1) {
                stats.push(printRecordStats(data[recordId]));
            }
        });

        return stats;
    }

    flush() {
        return this.records.flush().map((item) => ({
            id: item.recordId,
            ic: item.impressionCount,
            rc: item.readCount,
            it: item.impressionTime,
            rt: item.readTime,
        }));
    }
}
