import React from 'react';

import { IconSvg, IconComponent } from '.';

export const UserCheckIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M20.525 20.2C20.025 18.3 17.325 14.1 12.425 14.1C12.025 14.1 11.625 14.5 11.625 14.9C11.625 15.3 12.025 15.7 12.425 15.7C16.125 15.7 18.225 18.7 18.925 20.3H11.625C11.225 20.3 10.825 20.7 10.825 21.1C10.825 21.5 11.225 21.9 11.625 21.9H19.325C19.725 21.9 20.125 21.7 20.325 21.4C20.525 21 20.625 20.6 20.525 20.2Z" />
        <path d="M12.425 13.3C15.225 13.3 17.525 11 17.525 8.2C17.525 5.4 15.225 3 12.425 3C9.625 3 7.225 5.3 7.225 8.1C7.225 10.9 9.525 13.3 12.425 13.3ZM12.425 4.6C14.425 4.6 15.925 6.2 15.925 8.1C15.925 10.1 14.325 11.6 12.425 11.6C10.425 11.6 8.925 10 8.925 8.1C8.825 6.2 10.425 4.6 12.425 4.6Z" />
        <path d="M9.225 16L6.325 19.1L5.325 18C5.025 17.7 4.525 17.7 4.225 18C3.925 18.3 3.925 18.8 4.225 19.1L5.625 20.5C5.825 20.7 6.125 20.8 6.425 20.8C6.725 20.8 7.025 20.7 7.225 20.5L10.525 17C10.825 16.7 10.825 16.2 10.525 15.9C10.025 15.6 9.525 15.7 9.225 16Z" />
    </IconSvg>
);
