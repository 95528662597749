import React from 'react';

import { IconSvg, IconComponent } from '.';

export const PencilIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.89867 15.9825C4.93303 15.8387 5.00655 15.7073 5.11107 15.6028L16.0712 4.64264C16.5789 4.13495 17.402 4.13496 17.9097 4.64264L19.3239 6.05685C19.8316 6.56453 19.8316 7.38765 19.3239 7.89533L8.36376 18.8555C8.26193 18.9573 8.13449 19.0298 7.99489 19.0652L5.19683 19.7754C4.92539 19.8443 4.63769 19.7662 4.4383 19.5696C4.2389 19.373 4.15683 19.0864 4.22193 18.814L4.89867 15.9825ZM7.79808 17.1584L17.9804 6.97609L16.9905 5.98614L6.80813 16.1685L7.79808 17.1584Z"
        />
    </IconSvg>
);
