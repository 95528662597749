import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body17SemiboldTNumFontStyle } from 'services/typography';

export const Placeholder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &:after {
        content: attr(data-text);
        position: absolute;
        ${body17SemiboldTNumFontStyle};
        color: ${({ theme }) => theme.label.placeholder};
    }

    min-height: 380px;
    ${mediaQuery.lt1400} {
        min-height: 326px;
    }
    ${mediaQuery.lt1200} {
        min-height: 272px;
    }
    ${mediaQuery.lt960} {
        min-height: 221px;
    }
    ${mediaQuery.lt768} {
        min-height: 240px;
    }
    ${mediaQuery.lt480} {
        min-height: 160px;
    }
`;

export const ImptrackersWrapper = styled.div`
    position: absolute;
    opacity: 0;
    pointer-events: none;
`;

export const ImptrackerImg = styled.img`
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
`;
