import { socket } from 'services/webscokets';
import { useLocaleId } from 'hooks/useLocaleId';

export const useWebsocketChannel = (channelName?: string) => {
    const lcid = useLocaleId();

    const channel = channelName || `general_${lcid}`;

    return socket.getChannel(channel);
};
