import styled from 'styled-components';

import { postSidePadding } from '../styled';

export const Wrapper = styled.div`
    ${postSidePadding};

    margin-top: 8px;
    img {
        max-width: 100%;
        height: auto;
    }
`;
